.menuText:focus {
  background-color: transparent !important;
}

.menuText:active {
  background-color: transparent !important;
}

.underline {
  text-decoration: none;
  background-image: linear-gradient(#d14a76, #d14a76);
  background-size: 100% 0.2rem;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
}

.underlineSlide {
  text-decoration: none;
  background-image: linear-gradient(#d14a76, #d14a76);
  background-size: 0% 0.2rem;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

li:hover .underlineSlide,
li:focus .underlineSlide,
li:active .underlineSlide {
  background-size: 100% 0.1em;
}
