@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  font-family: "TT Norms", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #06070e;
}

@font-face {
  font-family: "TT Norms";
  font-weight: 400;
  font-style: normal;
  src: local("TT Norms"), url("./fonts/TTNorms-Regular.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 500;
  src: local("TT Norms"), url("./fonts/TTNorms-Medium.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 700;
  src: local("TT Norms"), url("./fonts/TTNorms-Bold.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-weight: 900;
  src: local("TT Norms"), url("./fonts/TTNorms-Black.otf") format("opentype");
}

@font-face {
  font-family: "TT Norms";
  font-style: italic;
  src: local("TT Norms"), url("./fonts/TTNorms-Italic.otf") format("opentype");
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #E5E7EB;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: #EFB4CC;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #D14A76;
  border-radius: 12px;
}

progress[value] {
  -webkit-appearance:none;
  -moz-appearance:none;        
  appearance: none;
  --background: #F2DBE2;  /* the backround color */
  --color: #D14A76; /* the color color */
  border: none; /* Firefox add a default border */
  position: absolute;
  width: 500px;
  height: 5px;
  margin: 0 32px 0 0;
  border-radius: 24px;
  background: var(--background);
}

/* for Firefox */

progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
}

/* For Chrome and Webkit */

progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}

progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}

