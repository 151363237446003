.switchInput[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 18px;
  background: #E5E7EB;
  border: 1px;
  border-color: #D14A76;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switchLabel:after {
  content: "";
  position: absolute;
  left: 0px;
  width: 18px;
  height: 18px;
  background: #FDFDFD;
  border-radius: 1.2rem;
  transition: 0.3s;
}

.switchInput + .switchLabel:after {
  border-width: 2px;
  border-color: #D14A76;
}

.switchInput:checked + .switchLabel {
  background: #D14A76;
}

.switchInput:checked + .switchLabel:after {
  width: 18px;
  height: 18px;
  left: 100%;
  transform: translateX(-100%);
}

.switchLabel:active:after {
  width: 12px
}