.underline {
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-size: 100% 0.2rem;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
}

.underlineSlide {
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-size: 0% 0.1rem;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.underlineSlide:hover,
.underlineSlide:focus,
.underlineSlide:active {
  background-size: 100% 0.1em;
}

.menuScrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.menuScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.menuScrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.menuScrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 767px) {
  .underline {
    background-image: none;
  }
}

@media (max-width: 975px) {
  .flowOver {
    min-width: calc(100vw - 42px);
  }
}

@media (min-width: 976px) and (max-width: 1023px) {
  .flowOver {
    min-width: calc(100vw - 74px);
  }
}
